import React, { useContext, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import ModalSvcContext from 'src/shared/services/modal/modal.context';
import APP_MODALS from 'src/static/enums/app.modals';
import QueryApi from 'src/shared/api/query-api';
import { toast } from 'react-toastify';

type DeleteConfirmationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    questId: string;
};

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, questId }: DeleteConfirmationModalProps) => {
    const modalSvc = useContext(ModalSvcContext);
    const modalRef = useRef<HTMLDivElement>(null);

    if (!isOpen) return null;

    const deleteMutation = useMutation(() => QueryApi.challenges.deleteQuest(questId), {
        onSuccess: () => {
            onConfirm();
            toast.success('Quest deleted successfully');
            modalSvc.closeModal(APP_MODALS.DELETE_QUEST);
        },
        onError: (error) => {
            toast.error('Failed to delete quest');
            console.error('Failed to delete quest:', error);
        },
    });

    const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        deleteMutation.mutate();
    };

    const handleClose = (e: React.MouseEvent<HTMLButtonElement> | MouseEvent) => {
        e.preventDefault();
        onClose();
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            onClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
            <div ref={modalRef} className="bg-white rounded-2xl p-6 w-full max-w-md">
                <div className="flex justify-between items-center pb-3">
                    <h2 className="text-xl font-semibold">Delete Quest</h2>
                    <button onClick={handleClose} className="text-gray-400 hover:text-gray-600">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <p className="mt-4 text-gray-600">Are you sure you want to delete this quest? Once deleted, it will be permanently removed from the airdrop platform and cannot be restored.</p>
                <div className="mt-6 flex justify-end space-x-4">
                    <button onClick={handleClose} className="bg-white border border-gray-300 text-gray-700 rounded-full w-full px-4 py-2 hover:bg-gray-50">
                        Cancel
                    </button>
                    <button onClick={handleConfirm} className="bg-red-500 text-white rounded-full px-4 py-2  w-full hover:bg-red-600">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirmationModal;
