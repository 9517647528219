enum ERROR_CODES {
	// image upload
	IMAGE_SIZE_TOO_LARGE = 'IMAGE_SIZE_TOO_LARGE',
	IMAGE_INVALID_TYPE = 'IMAGE_INVALID_TYPE',
	IMAGE_INVALID_DIMENSIONS = 'IMAGE_INVALID_DIMENSIONS',
	PWR_PROVIDER_NOT_DETECTED = 3000,
	IMAGE_PRESIGNED_URL_ERROR = 'IMAGE_PRESIGNED_URL_ERROR',
	UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR',
}

export default ERROR_CODES;
