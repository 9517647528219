// Importing necessary modules and defining constants
import { Signal, signal } from '@preact/signals-react';
import APP_MODALS from 'src/static/enums/app.modals';
import { ModalData } from 'src/shared/models/modals/modals.model';
import { APP_EVENTS } from 'src/static/enums/app.events';

// Defining the ModalService class
export default class ModalService {
	// Defining state variables
	private modals: Signal<ModalData[]> = signal([]);
	number = signal(0);
	private showGlassPanel: Signal<boolean> = signal(false);

	constructor() {
		// Event listener for modal changes
		const handleModalsChange = (e: CustomEvent<ModalData[]>) => {
			this.showGlassPanel.value = e.detail.length > 0;
			const html = document.querySelector('html') as HTMLElement;
			html.style.overflow = this.showGlassPanel.value ? 'hidden' : 'auto';
		};

		// Adding event listener for modal changes
		document.addEventListener(APP_EVENTS.MODALS_CHANGED, handleModalsChange as EventListener);
	}

	// Getter for glass panel visibility
	getGlassPanel() {
		return this.showGlassPanel.value;
	}

	// Getter for open modals
	getOpenModals() {
		return this.modals.value;
	}

	// Method to open a modal
	open(modalId: APP_MODALS, data?: any) {
		// Avoid opening the modal if it's already open
		console.log('Opening modal:', modalId, 'with data:', data);

		if (this.modals.value.map((m) => m.id).includes(modalId)) return;

		const _modalDetails = {
			id: modalId,
			show: true,
			data: data || {}, // Ensure data is an object even if undefined,
		};

		// Add the new modal to the list of modals
		this.modals.value = [...this.modals.value, _modalDetails];

		// Emit the modals changed event
		this.emitModalsChanged();
	}

	// Method to close a specific modal
	closeModal(modalId: APP_MODALS) {
		// Log the closing modal action

		// Remove the modal from the list
		const newModals = this.modals.value.filter((m) => m.id !== modalId);

		// Update the list of modals
		this.modals.value = [...newModals];

		// Emit the modals changed event
		this.emitModalsChanged();
		console.log("Closing modal:", modalId);

	}

	// Method to emit the modals changed event
	emitModalsChanged() {
		const modalsEvent = new CustomEvent<ModalData[]>(APP_EVENTS.MODALS_CHANGED, {
			detail: this.modals.value,
		});

		// Dispatch the event to notify listeners
		document.dispatchEvent(modalsEvent);
	}

	// Method to close the current modal
	closeCurrentModal() {
		// Check if there are no modals to close
		if (this.modals.value.length === 0) return;

		// Remove the last modal in the list
		const arr = this.modals.value;
		arr.pop() as ModalData;

		// Update the list of modals
		this.modals.value = [...arr];

		// Emit the modals changed event
		this.emitModalsChanged();
	}

	// Method to close all modals
	closeAllModals() {
		// Clear the list of modals
		this.modals.value = [];
	}
}
