import { ReactNode, useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import appStorage from 'src/shared/app-storage/app-storage';
import queryClient from 'src/shared/query-client';
import AuthSvcContext from 'src/shared/services/auth/auth.context';
import AuthService from 'src/shared/services/auth/auth.service';
import DocTitleSvcContext from 'src/shared/services/doc-title/doc-title.context';
import DocumentTitleService from 'src/shared/services/doc-title/doc-title.service';
import LayoutSvcContext from 'src/shared/services/layout/layout.context';
import LayoutService from 'src/shared/services/layout/layout.service';
import ModalSvcContext from 'src/shared/services/modal/modal.context';
import ModalService from 'src/shared/services/modal/modal.service';
import TestSvcContext from 'src/shared/services/test/test.context';
import TestService from 'src/shared/services/test/test.service';
import UserSvcContext from 'src/shared/services/user/user.context';
import UserService from 'src/shared/services/user/user.service';
import PwrService from 'src/shared/services/pwr/pwr.service';
import PwrSvcContext from 'src/shared/services/pwr/pwr.context';

const testSvc = new TestService();
const titleSvc = new DocumentTitleService();
const layoutSvc = new LayoutService();
const modalSvc = new ModalService();
const pwrSvc = new PwrService();
const authSvc = new AuthService(appStorage, pwrSvc);
const userSvc = new UserService();

function AppUISvcs({ children }: { children: ReactNode }) {
	return (
		<DocTitleSvcContext.Provider value={titleSvc}>
			<LayoutSvcContext.Provider value={layoutSvc}>
				<ModalSvcContext.Provider value={modalSvc}>
					{children}
				</ModalSvcContext.Provider>
			</LayoutSvcContext.Provider>
		</DocTitleSvcContext.Provider>
	);
}

function AppFnSvcs({ children }: { children: ReactNode }) {
	return (
		<PwrSvcContext.Provider value={pwrSvc}>
			<AuthSvcContext.Provider value={authSvc}>
				<UserSvcContext.Provider value={userSvc}>
					{children}
				</UserSvcContext.Provider>
			</AuthSvcContext.Provider>
		</PwrSvcContext.Provider>
	);
}

type ContextComponentProps = {
	children: ReactNode;
};

export default function ContextComponent({ children }: ContextComponentProps) {
	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await fetch('/api/user'); // Adjust the API endpoint as needed
				const userData = await response.json();
				userSvc.setUserData(userData);
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		};

		fetchUserData();
	}, []);

	return (
		<AppUISvcs>
			<TestSvcContext.Provider value={testSvc}>
				<QueryClientProvider client={queryClient}>
					<AppFnSvcs>{children}</AppFnSvcs>
				</QueryClientProvider>
			</TestSvcContext.Provider>
		</AppUISvcs>
	);
}