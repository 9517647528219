// src/shared/utils/broadcastChannel.ts
import AuthService from 'src/shared/services/auth/auth.service';
import { toast } from 'react-toastify';

let isListenerInitialized = false;

export const setupTwitterLoginListener = (
    authSvc: AuthService,
    setIsModalOpen: (isOpen: boolean) => void
) => {
    if (isListenerInitialized) return () => { };

    const channel = new BroadcastChannel('twitter-login');

    channel.onmessage = async (event) => {
        const { burnerToken } = event.data;
        if (burnerToken) {
            try {
                const response = await authSvc.twitterLogin(burnerToken);
                console.log('Token sent to backend successfully');
                localStorage.setItem('bearerToken', response.token); // Save token to localStorage
                setIsModalOpen(false); // Close modal on success
                toast.success('Logged in successfully!');
            } catch (error: any) {
                console.error('Error sending token to backend:', error.message);
                toast.error('Failed to log in.');
            }
        }
    };

    isListenerInitialized = true;

    return () => {
        channel.close();
        isListenerInitialized = false;
    };
};
