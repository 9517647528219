import PwrService from 'src/shared/services/pwr/pwr.service';
import { VerifyWalletDto } from '../dto/verifyWallet/verifyWallet.dto';

export default {
    verifyWalletInstall: async function (
        pwrSvc: PwrService,
        dto: VerifyWalletDto,
        from: string
    ): Promise<string> {
        return pwrSvc.dataTransaction({
            data: {
                ...dto,
                action: 'verify-wallet-install',
            },
            vmId: '888',
            from,
        });
    },
};
