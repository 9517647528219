import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TimeLeft {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

const CountdownContext = createContext<TimeLeft | undefined>(undefined);

const calculateTimeLeft = (endTime: number): TimeLeft => {
	const total = endTime - Date.now();

	const days = Math.floor(total / (1000 * 60 * 60 * 24));
	const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
	const minutes = Math.floor((total / (1000 * 60)) % 60);
	const seconds = Math.floor((total / 1000) % 60);

	return { days, hours, minutes, seconds };
};

interface CountdownProviderProps {
	children: ReactNode;
	endTime: number;
}

export const CountdownProvider: React.FC<CountdownProviderProps> = ({ children, endTime }) => {
	const [timeLeft] = useState<TimeLeft>(calculateTimeLeft(endTime));

	// useEffect(() => {
	//     const socket = new WebSocket('ws://your-websocket-url'); // replace with your WebSocket URL

	//     socket.onmessage = (event) => {
	//         const newEndTime = parseInt(event.data, 10);
	//         setTimeLeft(calculateTimeLeft(newEndTime));
	//     };

	//     const timer = setInterval(() => {
	//         setTimeLeft(calculateTimeLeft(endTime));
	//     }, 1000);

	//     return () => {
	//         clearInterval(timer);
	//         socket.close();
	//     };
	// }, [endTime]);

	return <CountdownContext.Provider value={timeLeft}>{children}</CountdownContext.Provider>;
};

export const useCountdown = (): TimeLeft => {
	const context = useContext(CountdownContext);
	if (context === undefined) {
		throw new Error('useCountdown must be used within a CountdownProvider');
	}
	return context;
};
