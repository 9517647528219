import React, { useRef, useEffect, useState } from 'react';
import QueryApi from 'src/shared/api/query-api';

type TelegramModalProps = {
    isOpen: boolean;
    onClose: () => void;
    callerId: string;
    onConnect: () => void;
};

const TelegramModal: React.FC<TelegramModalProps> = ({ isOpen, onClose, callerId, onConnect }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [htmlContent, setHtmlContent] = useState<string | null>(null);

    const handleOutsideClick = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            onClose();
            setHtmlContent(null); // Reset the HTML content when modal is closed
        }
    };

    const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        const contentContainer = modalRef.current?.querySelector('#telegram-content');
        if (contentContainer) {
            const link = contentContainer.querySelector('a')?.href;
            if (link) {
                window.open(link, '_blank', '');
            }
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);

            const fetchTelegramConnect = async () => {
                try {
                    const htmlResponse = await QueryApi.challenges.connectTelegram(callerId);
                    console.log("This is the html response: ", htmlResponse);

                    setHtmlContent(htmlResponse);
                    onConnect(); // Invoke the callback after successful connection
                } catch (error) {
                    console.error('Error connecting to Telegram:', error);
                }
            };

            fetchTelegramConnect();
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen, callerId]);

    useEffect(() => {
        if (htmlContent && modalRef.current) {
            const contentContainer = modalRef.current.querySelector('#telegram-content');
            if (contentContainer) {
                contentContainer.innerHTML = htmlContent;

                const scriptTag = contentContainer.querySelector('script');
                if (scriptTag) {
                    const newScriptTag = document.createElement('script');
                    newScriptTag.src = scriptTag.src;
                    newScriptTag.async = true;
                    Object.keys(scriptTag.dataset).forEach(key => {
                        newScriptTag.dataset[key] = scriptTag.dataset[key];
                    });
                    contentContainer.appendChild(newScriptTag);
                }
            }
        }
    }, [htmlContent]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[8000000]">
            <div
                ref={modalRef}
                className="bg-white dark:bg-zinc-900 rounded-2xl p-8 w-full max-w-md m-4"
            >
                <div className="flex justify-between items-center mb-4">
                    <h4 className="text-black dark:text-white text-2xl font-bold font-['Space Grotesk'] leading-9">
                        Log In
                    </h4>
                    <button onClick={() => {
                        onClose();
                        setHtmlContent(null); // Reset the HTML content when modal is closed
                    }} className="text-black dark:text-white text-xl">
                        ✕
                    </button>
                </div>

                <p className="text-black dark:text-white text-base mb-4">
                    Connect with Telegram
                </p>
                <div onClick={handleContentClick} style={{ cursor: 'pointer' }}>
                    <div id="telegram-content" />
                </div>
            </div>
        </div>
    );
};

export default TelegramModal;
