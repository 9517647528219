import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ROUTES from 'src/static/router.data';

function getRawAccessToken() {
	const queryString = window.location.search.substring(1); // Get query string, skip the ?
	const params = queryString.split('&'); // Split the query string on '&'

	for (const param of params) {
		const pair = param.split('='); // Split each parameter into key and value
		if (pair[0] === 'burner') {
			return pair[1]; // Return the raw value without decoding
		}
	}
	return null; // Return null if the access token is not found
}

export default function AuthCallbackPage() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const success = searchParams.get('success');
	const burnerToken = getRawAccessToken();

	useEffect(() => {
		if (!success || !burnerToken) {
			console.error('Login failed: Missing success or burner token.');
			navigate(ROUTES.root);
			return;
		}

		// Use BroadcastChannel to communicate the burner token
		const bc = new BroadcastChannel('twitter-login');
		bc.postMessage({ burnerToken, origin: 'PWRQuests-auth-callback' });

		// Close the new tab after successful login
		const isAndroid = /Android/i.test(navigator.userAgent);

		if (isAndroid) {
			// For Android devices, we can just close the window
			window.close();
		} else {
			// Attempt to close the window for other devices
			window.close();

			// Fallback mechanism if window.close() doesn't work
			setTimeout(() => {
				if (!window.closed) {
					navigate(ROUTES.root);
				}
			}, 1000);
		}

		// Clean up the BroadcastChannel
		return () => {
			bc.close();
		};
	}, [success, burnerToken, navigate]);

	return null; // Return null to avoid rendering any UI
}