enum APP_EVENTS {
	MODALS_CHANGED = 'modals-changed',
	MODAL_OPENED = 'modal-opened',
	MODAL_CLOSED = 'modal-closed',

	AUTH_LOGGED_IN = 'event.auth.loggedin',
	AUTH_SESSION_RESTORED = 'event.auth.sessionrestored',
	AUTH_LOGGED_OUT = 'event.auth.loggedout',
	//wallet events
	WALLET_CONNECTED = 'event.wallet.connected',
	WALLET_DICONNECTED = 'event.wallet.disconnected',
	WALLET_AUTH = 'event.wallet.auth'

}

export { APP_EVENTS };
