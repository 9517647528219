import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

type CustomLinkProps = {
	href?: string;
};

type Props = {
	children: React.ReactNode;
	className: string;
	tag_type?: 'def' | 'a' | 'link';
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
	React.AnchorHTMLAttributes<HTMLAnchorElement> &
	CustomLinkProps;

export default function Button({ children, className, tag_type = 'def', href, ...rest }: Props) {
	const buttonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);

	useEffect(() => {
		const btn = buttonRef.current;
		if (!btn) return;

		const rippleEffect = (event: MouseEvent) => {
			const circle = document.createElement('span');
			const diameter = Math.max(btn.clientWidth, btn.clientHeight);
			const radius = diameter / 2;

			circle.style.width = circle.style.height = `${diameter}px`;
			circle.style.left = `${event.clientX - btn.offsetLeft - radius}px`;
			circle.style.top = `${event.clientY - btn.offsetTop - radius}px`;
			circle.classList.add('ripple');

			const ripple = btn.getElementsByClassName('ripple')[0];
			if (ripple) {
				ripple.remove();
			}

			btn.appendChild(circle);
		};

		btn.addEventListener('click', rippleEffect as EventListener);


		return () => {
			btn.removeEventListener('click', rippleEffect as EventListener);
		};
	}, []);

	if (tag_type === 'a')
		return (
			<a
				className={`comp_button flex justify-center items-center ${className}`}
				{...rest}
				ref={buttonRef as React.Ref<HTMLAnchorElement>}
			>
				{children}
			</a>
		);

	if (tag_type === 'link')
		return (
			<Link
				to={href || '/'}
				className={`comp_button flex justify-center items-center ${className}`}
				{...rest}
				ref={buttonRef as React.Ref<HTMLAnchorElement>}
			>
				{children}
			</Link>
		);

	return (
		<button
			className={`comp_button transition duration-200 ease-in-out ${className}`}
			{...rest}
			ref={buttonRef as React.Ref<HTMLButtonElement>}
		>
			{children}
		</button>
	);
}