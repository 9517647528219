import { Signal, signal } from '@preact/signals-react';
import { PwrProviderNotDetectedError } from 'src/static/app.errors';
import { APP_EVENTS } from 'src/static/enums/app.events';
import {

    DataTxn,
} from '../../../../src/types/global';
import axios from 'axios';
import { toast } from 'react-toastify';

type WalletModel = {
    addresses: string[];
    isConnected: boolean;
};

export default class PwrService {
    // state
    private detected: Signal<boolean> = signal(false);
    public provider: any;

    // wallet
    private wallet: Signal<WalletModel> = signal({
        addresses: [],
        isConnected: false,
    });

    getWallet(): WalletModel {
        return this.wallet.value;
    }

    // *~~~ Constructor ~~~* //


    constructor() {
        if (window.pwr) {
            this.detected.value = true;
            this.provider = window.pwr;
        } else {
            this.detected.value = false;
            this.provider = null;
        }
    }
    async initializeProvider() {
        try {
            this.provider = await this.getProvider(); // Replace with your actual provider initialization logic
        } catch (error) {
            console.warn("PWR provider is not available. Please ensure the PWR wallet is installed on your device.");
            this.provider = null; // Set provider to null if not detected
        }
    }

    async init() {
        // 1. detect provider
        // 2. get provider

        await new Promise((_) => setTimeout(_, 200));

        // *~~*~~*~~ Events ~~*~~*~~* //

        if (this.provider) {
            console.log("Test before connected addresses")

            const connectedAddresses = await this.provider.getConnections();

            console.log("CONNECTED ADDRESSESS: ", connectedAddresses)
            if (connectedAddresses.length > 0) {
                this.wallet.value = {
                    addresses: connectedAddresses,
                    isConnected: true,
                };
            }
            this.provider.onConnect.addListener((addresses: string[]) => {
                this.wallet.value = {
                    addresses,
                    isConnected: true,
                };

                const walletConnectEvent = new CustomEvent(APP_EVENTS.WALLET_CONNECTED, {
                    detail: { addresses },
                });

                document.dispatchEvent(walletConnectEvent);
            });

            this.provider.onDisconnect.addListener((address: string) => {
                if (
                    this.wallet.value.addresses.length > 0 &&
                    this.wallet.value.addresses[0] === address
                ) {
                    this.wallet.value = {
                        addresses: [],
                        isConnected: false,
                    };

                    const walletDisconnectedEvent = new CustomEvent(APP_EVENTS.WALLET_DICONNECTED);
                    document.dispatchEvent(walletDisconnectedEvent);
                }
            });
        }
    }

    //Comment on a Proposal (Transaction based)
    async dataTransaction(txnData: DataTxn) {
        if (!this.provider) {
            const error = new PwrProviderNotDetectedError();
            console.error("Provider not detected error", error);
            throw error;
        }
        try {
            return await this.provider.dataTransaction(txnData);
        } catch (error) {
            console.error("Data transaction failed", error);
            throw error;
        }
    }
    async verifyWalletInstall(txnData: DataTxn): Promise<any> {
        try {
            const connectedAddresses = await this.provider.getConnections();
            if (!this.provider) {
                throw new Error('PWR provider is not initialized');
            }
            if (connectedAddresses == 0) {
                toast.error("Please install our wallet from chrome extension")
                toast.error("Visit our main page to get wallet")
            }
            const result = await this.provider.dataTransaction(txnData);
            return result;
        } catch (error) {
            console.error("Error during wallet verification:", error);
            throw new Error("Wallet verification failed");
        }
    }
    // async verifyWalletInstall(txnData: DataTxn) {
    //     if (!this.provider) throw new PwrProviderNotDetectedError();
    //     return this.provider.dataTransaction(txnData);
    // }
    // async verifyWalletInstall(txnData: DataTxn) {
    //     if (!this.provider) throw new PwrProviderNotDetectedError();
    //     return this.provider.dataTransaction(txnData);
    // }
    async getWalletAddress(): Promise<string> {
        if (!this.provider) {
            throw new PwrProviderNotDetectedError();
        }
        const addresses = await this.provider.getConnections();
        console.log("These are the addresses: ", addresses)
        if (addresses.length === 0) {
            throw new Error('No wallet addresses connected');
        }
        return addresses[0];
    }
    async addWallet(data: { userId: string; walletAddress: string }) {
        const token = localStorage.getItem('bearerToken');
        if (!token) {
            throw new Error('No bearer token found in local storage');
        }

        try {
            console.log('Sending wallet data:', data); // Log the payload
            const response = await axios.post(`${process.env.VITE_APP_AIRDROPHOST}/api/wallets/addWallet`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error: any) {
            if (error.response?.status === 404) {
                console.log('Wallet is already connected.');
                toast.success('Wallet is already connected. You can now verify this challenge.');
                return;
            }
            console.error('Error adding wallet:', error.response?.data || error.message); // Log the error response
            throw error;
        }
    }

    // *~~*~~*~~ External connection ~~*~~*~~* //
    // async connect(): Promise<any> {
    //     if (!this.provider) {
    //         this.initializeProvider(); // Ensure provider is initialized before connecting
    //         if (!this.provider) throw new PwrProviderNotDetectedError();
    //     }

    //     // Check if already connected
    //     const connections = await this.provider.getConnections();
    //     console.log("CONNECTIONS: ", connections)
    //     if (connections && connections.length > 0) {
    //         localStorage.setItem('walletAddress', connections[0]); // Store the wallet address
    //         return connections; // Return the existing connection
    //     }

    //     const newConnection = await this.provider.connect();
    //     console.log("New Connection: ", newConnection)
    //     localStorage.setItem('walletAddress', newConnection.addresses[0]); // Store the wallet address
    //     return newConnection;
    // }

    async connect(): Promise<any> {
        let retryCount = 0;
        const maxRetries = 3;
        while (retryCount < maxRetries) {
            try {
                if (!this.provider) {
                    this.provider.connect();
                    if (!this.provider) throw new PwrProviderNotDetectedError();
                }
                const connections = await this.provider.getConnections();
                if (connections && connections.length > 0) {
                    localStorage.setItem('walletAddress', connections[0]);
                    return connections;
                }
                const newConnection = await this.provider.connect();
                localStorage.setItem('walletAddress', newConnection.addresses[0]);
                return newConnection;
            } catch (error) {
                console.error(`Connection attempt ${retryCount + 1} failed`, error);
                retryCount++;
                if (retryCount >= maxRetries) {
                    throw new Error('Failed to connect after multiple attempts');
                }
            }
        }
    }

    getProvider() {
        if (!this.detected.value) throw new PwrProviderNotDetectedError();
        return this.provider;
    }



    // #endregion
}
