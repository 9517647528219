import React, { useRef, useEffect } from 'react';
import Button from 'src/components/internal/button/button.component';

type LoginModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onConnect: () => void;
	referralCode?: string; // Add referralCode as an optional prop
};

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose, onConnect, referralCode }) => {
	const modalRef = useRef<HTMLDivElement>(null);

	const handleOutsideClick = (event: MouseEvent) => {
		if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
			onClose();
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [isOpen]);

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[8000000]">
			<div
				ref={modalRef}
				className="bg-white dark:bg-zinc-900 rounded-2xl p-8 w-full max-w-md m-4"
			>
				<div className="flex justify-between items-center mb-4">
					<h4 className="text-black dark:text-white text-2xl font-bold font-['Space Grotesk'] leading-9">
						Log In
					</h4>
					<button onClick={onClose} className="text-black dark:text-white text-xl">
						✕
					</button>
				</div>

				{referralCode && (
					<p className="text-black dark:text-white text-base mb-4">
						You have been invited to the app
					</p>
				)}

				<p className="text-black dark:text-white text-base mb-4">
					Welcome to PWR Airdrops! Login or Connect to join challenges and get PWR Point
					Rewards.
				</p>
				<Button onClick={onConnect} className="blue  w-full ">
					<span>
						<i className="ml-3 fa-brands fa-x-twitter text-white "></i> Connect with X
						(Twitter)
					</span>
				</Button>
			</div>
		</div>
	);
};

export default LoginModal;
